"use client";

import { SerializablePreloadedQuery } from "@/relay/loadSerializableQuery";
import { graphql, usePreloadedQuery, useRelayEnvironment } from "react-relay";
import useSerializablePreloadedQuery from "@/relay/useSerializablePreloadedQuery";
import React from "react";

import AdminAuthContextProvider from "@/admin/context/AdminAuthContext";
import NavBar from "@/auth/components/NavBar";
import Link from "next/link";
import NotFoundPageQueryNode, {
  NotFoundPageQuery,
} from "@generated/NotFoundPageQuery.graphql";

const NotFoundPage: React.FC<{
  preloadedQuery: SerializablePreloadedQuery<
    typeof NotFoundPageQueryNode,
    NotFoundPageQuery
  >;
}> = ({ preloadedQuery }) => {
  const environment = useRelayEnvironment();

  const queryRef = useSerializablePreloadedQuery(environment, preloadedQuery);

  const data = usePreloadedQuery(
    graphql`
      query NotFoundPageQuery {
        ...AdminAuthContext_authState
      }
    `,
    queryRef,
  );

  return (
    <AdminAuthContextProvider authState={data}>
      <div className="flex min-h-dvh flex-col">
        <NavBar />
        <NotFound />
      </div>
    </AdminAuthContextProvider>
  );
};

const NotFound: React.FC = () => {
  return (
    <main className="container mx-4 flex flex-1 flex-col items-center justify-center sm:mx-6">
      <h1 className="text-7xl font-bold tracking-tight">404</h1>
      <Link href="/">Return Home</Link>
    </main>
  );
};

export default NotFoundPage;
