/**
 * @generated SignedSource<<ed19261f604dd013251b6e558b723cd8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type NotFoundPageQuery$variables = Record<PropertyKey, never>;
export type NotFoundPageQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AdminAuthContext_authState">;
};
export type NotFoundPageQuery = {
  response: NotFoundPageQuery$data;
  variables: NotFoundPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "size",
      "value": 360
    }
  ],
  "kind": "ScalarField",
  "name": "image",
  "storageKey": "image(size:360)"
},
v2 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "slug",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  },
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "WebsiteSettings",
    "kind": "LinkedField",
    "name": "websiteSettings",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CustomDomain",
        "kind": "LinkedField",
        "name": "customDomain",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "domain",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "verificationStatus",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v0/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotFoundPageQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AdminAuthContext_authState"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NotFoundPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "normalizedEmail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "givenName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canCreateOrganization",
                "storageKey": null
              },
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationMember",
                "kind": "LinkedField",
                "name": "organizationsAsMember",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "role",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": (v2/*: any*/),
                    "storageKey": null
                  },
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "excludeOrganizationsAsMember",
                    "value": true
                  }
                ],
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organizationsAsInstructor",
                "plural": true,
                "selections": (v2/*: any*/),
                "storageKey": "organizationsAsInstructor(excludeOrganizationsAsMember:true)"
              }
            ],
            "type": "User",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v0/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "419976b1d32f78bf0423c0532fe70a67",
    "id": null,
    "metadata": {},
    "name": "NotFoundPageQuery",
    "operationKind": "query",
    "text": "query NotFoundPageQuery {\n  ...AdminAuthContext_authState\n}\n\nfragment AdminAuthContext_authState on Query {\n  viewer {\n    __typename\n    ... on User {\n      id\n      email\n      normalizedEmail\n      givenName\n      lastName\n      canCreateOrganization\n      image(size: 360)\n      organizationsAsMember {\n        role\n        organization {\n          id\n          slug\n          name\n          status\n          image(size: 360)\n          websiteSettings {\n            customDomain {\n              domain\n              verificationStatus\n            }\n            id\n          }\n        }\n        id\n      }\n      organizationsAsInstructor(excludeOrganizationsAsMember: true) {\n        id\n        slug\n        name\n        status\n        image(size: 360)\n        websiteSettings {\n          customDomain {\n            domain\n            verificationStatus\n          }\n          id\n        }\n      }\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3bd7797c4857d116c7b7c854ffeeb987";

export default node;
